/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at http://mozilla.org/MPL/2.0/. */

// AUTOGENERATED BY glean_parser v14.5.2. DO NOT EDIT. DO NOT COMMIT.

import StringMetricType from '@mozilla/glean/private/metrics/string';

/**
 * The entrypoint experiment the user was assigned to. This value is specified
 * by query parameter `entrypoint_experiment` in the URL.
 *
 * Generated from `entrypoint.experiment`.
 */
export const experiment = new StringMetricType({
  category: 'entrypoint',
  name: 'experiment',
  sendInPings: ['accounts-events', 'events'],
  lifetime: 'application',
  disabled: false,
});

/**
 * The entrypoint variation the user was assigned to. This value is specified
 * by query parameter `entrypoint_variation` in the URL.
 *
 * Generated from `entrypoint.variation`.
 */
export const variation = new StringMetricType({
  category: 'entrypoint',
  name: 'variation',
  sendInPings: ['accounts-events', 'events'],
  lifetime: 'application',
  disabled: false,
});
