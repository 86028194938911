/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at http://mozilla.org/MPL/2.0/. */

// AUTOGENERATED BY glean_parser v14.5.2. DO NOT EDIT. DO NOT COMMIT.

import EventMetricType from '@mozilla/glean/private/metrics/event';

/**
 * User clicks "Confirm pairing" on the mobile pair page after scanning QR code to
 * pair device
 *
 * Generated from `cad_mobile_pair.submit`.
 */
export const submit = new EventMetricType(
  {
    category: 'cad_mobile_pair',
    name: 'submit',
    sendInPings: ['events'],
    lifetime: 'ping',
    disabled: false,
  },
  []
);

/**
 * User viewed the mobile pair page after scanning QR code to pair device
 *
 * Generated from `cad_mobile_pair.view`.
 */
export const view = new EventMetricType(
  {
    category: 'cad_mobile_pair',
    name: 'view',
    sendInPings: ['events'],
    lifetime: 'ping',
    disabled: false,
  },
  []
);
