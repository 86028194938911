/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at http://mozilla.org/MPL/2.0/. */

// AUTOGENERATED BY glean_parser v14.5.2. DO NOT EDIT. DO NOT COMMIT.

import StringMetricType from '@mozilla/glean/private/metrics/string';

/**
 * one of 'mobile', 'tablet', or 'desktop'
 *
 * Generated from `session.device_type`.
 */
export const deviceType = new StringMetricType({
  category: 'session',
  name: 'device_type',
  sendInPings: ['accounts-events', 'events'],
  lifetime: 'application',
  disabled: false,
});

/**
 * entrypoint to the service
 *
 * Generated from `session.entrypoint`.
 */
export const entrypoint = new StringMetricType({
  category: 'session',
  name: 'entrypoint',
  sendInPings: ['accounts-events', 'events'],
  lifetime: 'application',
  disabled: false,
});

/**
 * an ID generated by FxA for its flow metrics
 *
 * Generated from `session.flow_id`.
 */
export const flowId = new StringMetricType({
  category: 'session',
  name: 'flow_id',
  sendInPings: ['accounts-events', 'events'],
  lifetime: 'application',
  disabled: false,
});
