/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at http://mozilla.org/MPL/2.0/. */

// AUTOGENERATED BY glean_parser v14.5.2. DO NOT EDIT. DO NOT COMMIT.

import EventMetricType from '@mozilla/glean/private/metrics/event';

/**
 * User viewed the default page state on pair/unsupported after trying to access
 * the pair flow
 *
 * Generated from `cad_redirect_desktop.default_view`.
 */
export const defaultView = new EventMetricType(
  {
    category: 'cad_redirect_desktop',
    name: 'default_view',
    sendInPings: ['events'],
    lifetime: 'ping',
    disabled: false,
  },
  []
);

/**
 * User clicked "Download Firefox" on the "Switch to Firefox" page after trying to
 * access the pair flow
 *
 * Generated from `cad_redirect_desktop.download`.
 */
export const download = new EventMetricType(
  {
    category: 'cad_redirect_desktop',
    name: 'download',
    sendInPings: ['events'],
    lifetime: 'ping',
    disabled: false,
  },
  []
);

/**
 * User viewed the "Switch to Firefox" page after trying to access the pair flow
 *
 * Generated from `cad_redirect_desktop.view`.
 */
export const view = new EventMetricType(
  {
    category: 'cad_redirect_desktop',
    name: 'view',
    sendInPings: ['events'],
    lifetime: 'ping',
    disabled: false,
  },
  []
);
